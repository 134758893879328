.rounded-button {
    border-radius: 50px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    background-color: #707b86;
    color: black;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.rounded-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

.rounded-button:active {
    transform: scale(0.95);
}


@media (max-width: 768px) {
    .rounded-button {
        padding: 8px 16px;
        font-size: 14px;
    }
}